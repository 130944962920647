<template>
  <availability :current-user-id="provider.user_id" />
</template>
<script>
import { mapState } from "vuex";
import Availability from "../../../components/Availability.vue";
export default {
  name: "ProviderAvailability",
  components: {
    Availability,
  },
  computed: {
    ...mapState({
      provider: (state) => state.providers.provider,
    }),
  },
};
</script>
